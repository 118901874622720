import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../common/Navbar/Navbar';
import CalculationInputPanel from './CalculationInputPanel/CalculationInputPanel';
import CalculationGridPanel from './CalculationGridPanel/CalculationGridPanel';
import CalculationGridPanelUpdated from './CalculationGridPanel/CalculationGridPanelUpdated'
import PageDescription from './PageDescription';
import useAuth from '../../hooks/useAuth';
import Login from '../LoginLayout/loginLayout';
import Calculator from '../../services/CalculationService';
import Estimator from '../../services/Estimator';
import useData from '../../hooks/useData';

// loop through values get the matching map to the datasource
// then replaces our values tiers, productCategory, name, description, cpt
// keep order and calculationValue
// name and description will be the same but this is fine as they map to each other
const getMarchMappings = (values, dataSource) => {

  const mappedValues = {}

  Object.entries(values).forEach(([key, value], index) => {
    value.forEach(sku => {
      if (sku.mapping) {
        const { group, name } = sku.mapping

        if (dataSource[group] && dataSource[group][name]) {
          if (!mappedValues?.[group]) {
            mappedValues[group] = []
          }
          const marchData = dataSource[group][name]

          const tiers = [marchData.freeUsageThreshold, ...marchData.tiers]
          const costPerThousand = [0, ...marchData.costPerThousand]

          mappedValues[group].push({
            name,
            productCategory: group,
            description: name,
            calculationValue: sku.calculationValue,
            order: sku.order,
            mapping: sku.mapping,
            tiers,
            costPerThousand,
            freeUsageThreshold: marchData.freeUsageThreshold
          })
        }
      }
    })
  })

  return mappedValues
}

// ? This component will handle all interaction logic of the calculator
function CalculatorLayout() {
  const { isLoggedIn, getData } = useAuth();
  const { exchangeRate } = useData()
  const [calculationData, setCalculationData] = useState()
  const [currentExpandedPanel, setCurrentExpandedPanel] = useState({
    calculationGrid: false,
    calculationGridMarch: false
  });

  const handleAccordionChange = (panelDescription) => (event, isExpanded) => {
    setCurrentExpandedPanel({
      ...currentExpandedPanel,
      [panelDescription]: isExpanded
    });
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setCurrentExpandedPanel({
      calculationGridMarch: true
    });

    setSubmitting(true);
    const data = await getData()
    const productArray = await Calculator(values, data, exchangeRate);
    const estimatedData = await Estimator(productArray, exchangeRate, false)

    setCalculationData(estimatedData)
    setSubmitting(false);
  };

  const handleClear = () => {
    setCalculationData([])
  }

  return (
    isLoggedIn ? (
      <>
        <Navbar position='sticky' />
        <ToastContainer />
        <Container className='lg:px-24' maxWidth="xl">
          <PageDescription />
          <Grid>
            <Grid item>
              <CalculationInputPanel handleSubmit={handleSubmit} handleClear={handleClear} />
            </Grid>
            {/* <Grid>
              <CalculationGridPanel data={calculationData} currentExpandedPanel={currentExpandedPanel} handleChange={handleAccordionChange} />
            </Grid> */}
            <Grid>
              <CalculationGridPanelUpdated data={calculationData} currentExpandedPanel={currentExpandedPanel} handleChange={handleAccordionChange} />
            </Grid>
          </Grid>
        </Container>
      </>
    ) : (
      <Login />
    )
  );
}

export default CalculatorLayout;
