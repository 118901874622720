import PropTypes from 'prop-types';
import { createContext, useState, useMemo, useCallback, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, getDocs } from 'firebase/firestore';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, signOut, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { dialogContentClasses } from '@mui/material';
import { elementAcceptingRef } from '@mui/utils';
import { firebaseConfig } from '../config/firebaseConfig';
import useLocalStorage from '../hooks/useLocalStorage';
import { backupData } from './dataBackupObject';

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);

let updatedTiers = {
  tiers: []
}

const AuthContext = createContext({
  method: 'firebase',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};
function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const auth = getAuth(firebaseApp);
  const [inputBoxData, setInputBoxData] = useState();
  const [marchDataCollection, setMarchDataCollection] = useState()
  const [outputData, setOutputData] = useState([]);

  const [placesInputData, setPlacesInputData] = useLocalStorage('placesInputData', []);
  const [routesInputData, setRoutesInputData] = useLocalStorage('routesInputData', []);
  const [mapsInputData, setMapsInputData] = useLocalStorage('mapsInputData', []);
  const [mapTilesInputData, setMapTilesInputData] = useLocalStorage('mapTilesInputData', []);
  const [environmentInputData, setEnvironmentInputData] = useLocalStorage('environmentInputData', []);
  const [legacyPlacesInputData, setLegacyPlacesInputData] = useLocalStorage('legacyPlacesInputData', []);
  const [legacyRoutesInputData, setLegacyRoutesInputData] = useLocalStorage('legacyRoutesInputData', []);

  useEffect(() => {
    if (inputBoxData?.Maps) {
      const mapsFormikValues = generateFormikInitialValues(inputBoxData.Maps, 'Maps');
      setMapsInputData(mapsFormikValues);
    }
    if (inputBoxData?.Routes) {
      const routesFormikValues = generateFormikInitialValues(inputBoxData.Routes, 'Routes');
      setRoutesInputData(routesFormikValues);
    }
    if (inputBoxData?.Places) {
      const placesFormikValues = generateFormikInitialValues(inputBoxData.Places, 'Places');
      setPlacesInputData(placesFormikValues);
    }
    if (inputBoxData?.Environment) {
      const environmentFormiKValues = generateFormikInitialValues(inputBoxData.Environment, 'Environment');
      setEnvironmentInputData(environmentFormiKValues);
    }
    if (inputBoxData?.MapTiles) {
      const mapTilesFormiKValues = generateFormikInitialValues(inputBoxData.MapTiles, 'Map Tiles');
      setMapTilesInputData(mapTilesFormiKValues);
    }
    if (inputBoxData?.LegacyPlaces) {
      const legacyPlacesFormikValues = generateFormikInitialValues(inputBoxData.LegacyPlaces, 'Places (Legacy)');
      setLegacyPlacesInputData(legacyPlacesFormikValues);
    }
    if (inputBoxData?.LegacyRoutes) {
      const legacyRoutesFormikValues = generateFormikInitialValues(inputBoxData.LegacyRoutes, 'Routes (Legacy)');
      setLegacyRoutesInputData(legacyRoutesFormikValues);
    }
  }, [inputBoxData]);

  const getData = useCallback(async () => {
    console.log('FirebaseContext getData | inputBoxData', inputBoxData);

    // const dataCollection = {};
    const updatedDataCollectionLocal = {}

    const updatedSnapshot = await getDocs(collection(db, 'billingscalculatorupdated'))
    // const querySnapshot = await getDocs(collection(db, 'billingcalculator'));

    // querySnapshot.forEach((doc) => {
    //   if (doc.id === 'Routes') {
    //     const routes = doc.data();
    //     dataCollection.routes = routes;
    //   } else if (doc.id === 'Maps') {
    //     const maps = doc.data();
    //     dataCollection.maps = maps;
    //   } else if (doc.id === 'Places') {
    //     const places = doc.data();
    //     dataCollection.places = places;
    //   } else if (doc.id === 'NewPlaces') {
    //     const newPlaces = doc.data();
    //     dataCollection.newPlaces = newPlaces;
    //   } else if (doc.id === 'Environment') {
    //     const environment = doc.data();
    //     dataCollection.environment = environment;
    //   } else if (doc.id === 'MapTiles') {
    //     const mapTiles = doc.data();
    //     dataCollection.mapTiles = mapTiles;
    //   } else if (doc.id === 'GeneralTiers') {
    //     generalTiers = doc.data()
    //   }
    // });

    updatedSnapshot.forEach((doc) => {
      if (doc.id === 'GeneralTiers') {
        updatedTiers = doc.data()
      } else {
        updatedDataCollectionLocal[doc.id] = doc.data();
      }
    });

    const updatedDataCollection = updatedDataCollectionLocal

    Object.entries(updatedDataCollectionLocal).forEach(([key, value], index) => {
      Object.entries(value).forEach(([sku, skuValues], index) => {
        updatedDataCollection[key][sku] = {
          ...skuValues,
          tiers: skuValues.tiers || updatedTiers.tiers
        }
      })
    });

    setInputBoxData(updatedDataCollection)

    return updatedDataCollection;
  }, []);

  const generateFormikInitialValues = (inputData, category = '') => {
    const generatedInputArray = [];

    Object.entries(inputData).forEach(([key, value], index) => {
      const tiers = value.tiers || updatedTiers.tiers
      generatedInputArray.push({
        name: key,
        productCategory: category,
        description: key,
        calculationValue: '',
        order: value.order,
        tiers: [value.freeUsageThreshold, ...tiers],
        costPerThousand: [0, ...value.costPerThousand],
        freeUsageThreshold: value.freeUsageThreshold
      });
    });

    generatedInputArray.sort((a, b) => {
      const keyA = a.order;
      const keyB = b.order;
      // Compare the 2 orders
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });

    return generatedInputArray;
  };

  const login = (email, password) => {
    const user = new Promise((resolve, reject) => {
      signInWithEmailAndPassword(auth, email, password)
        .then((user) => {
          setUser(user);
          setIsLoggedIn(true);
          resolve(user);
        })
        .catch((error) => {
          console.log(error.message);
          reject();
        });
    });
    return user;
  };

  const loginWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth(firebaseApp);

    const user = new Promise((resolve, reject) => {
      signInWithPopup(auth, provider)
        .then((result) => {
          if (!validAccount(result.user.email)) {
            auth.currentUser.delete();
            return;
          }
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          const { user } = result;
          setUser(user);
          setIsLoggedIn(true);
          resolve(user);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          const { email } = error;
          const credential = GoogleAuthProvider.credentialFromError(error);
          reject();
        });
    });
  };

  const validAccount = (userEmail) => userEmail.split('@')[1] === '28east.co.za';

  const logout = async () => {
    await signOut();
    setIsLoggedIn(false);
  };

  const resetPassword = async (email) => {
    await sendPasswordResetEmail(email);
  };

  const contextValues = useMemo(
    () => ({
      method: 'firebase',
      user,
      isLoggedIn,
      setIsLoggedIn,
      login,
      logout,
      resetPassword,
      loginWithGoogle,
      getData,
      inputBoxData,
      marchDataCollection,
      environmentInputData,
      mapTilesInputData,
      placesInputData,
      legacyPlacesInputData,
      legacyRoutesInputData,
      mapsInputData,
      routesInputData,
      outputData,
      setOutputData
    }),
    [user, isLoggedIn, login, loginWithGoogle, getData, inputBoxData, marchDataCollection, environmentInputData, mapTilesInputData, placesInputData, legacyPlacesInputData, legacyRoutesInputData, mapsInputData, routesInputData, outputData]
  );

  return <AuthContext.Provider value={contextValues}>{children}</AuthContext.Provider>;
}
export { AuthContext, AuthProvider };
